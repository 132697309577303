import { graphql } from 'gatsby';
import Component from 'src/templates/HomePage';
export default Component;

export const pageQuery = graphql`
  query {
    posts: allMarkdownRemark(
      sort:   { fields: [frontmatter___date___published], order: DESC }
      filter: { fields: { type: { eq: "blog" } } }
      limit:  7
    ) { ...MarkdownFragment }

    talks: allMarkdownRemark(
      sort:   { fields: [frontmatter___date___published], order: DESC }
      filter: { fields: { type: { eq: "talks" } } }
      limit:  5
    ) { ...MarkdownFragment }

    site {
      siteMetadata {
        social { github, twitter, linkedin, stackoverflow }
      }
    }
  }

  fragment MarkdownFragment on MarkdownRemarkConnection {
    edges {
      node {
        excerpt
        frontmatter {
          date { published }
          title
          description
          external { url }
        }
        fields { slug }
      }
    }
  }
`;
