import _ from 'lodash';
import React from 'react';
import { Link } from 'gatsby';
import { FaGithubAlt, FaTwitter, FaStackOverflow } from 'react-icons/fa';
import { GrLinkedinOption } from 'react-icons/gr';
import { RiExternalLinkLine } from 'react-icons/ri';

import DateTime from 'src/components/DateTime';
import ExternalLink from 'src/components/ExternalLink';
import Layout from 'src/components/Layout';
import SEO from 'src/components/SEO';
import URLs from 'src/utils/urls';
import * as styles from './HomePage.module.sass';


const SOCIALS = [
  { name: 'github',        icon: <FaGithubAlt/>      },
  { name: 'twitter',       icon: <FaTwitter/>        },
  { name: 'stackoverflow', icon: <FaStackOverflow/>  },
  { name: 'linkedin',      icon: <GrLinkedinOption/> },
];


const HomePage = ({ data, location }) => {
  const socialLinks = data.site.siteMetadata.social;
  const posts = data.posts.edges;
  const talks = data.talks.edges;

  return (
    <Layout location={location}>
      <SEO
        title="Home"
        location={location}
        isHomepage
      />
      <HomePageHeader socialLinks={socialLinks} />
      <RecentContent posts={posts} talks={talks} />
    </Layout>
  )
};


const HomePageHeader = ({ socialLinks }) => {
  return (
    <header className={styles.header}>
      <h1 className={styles.name}>
        <Link to={`/`}>Sheharyar Naseer</Link>
      </h1>

      <div className={styles.intro}>
        <p>
          I'm an Indie Nomad developer currently working out
          of Lahore (previously SF & NYC).
        </p>

        <p>
          I <Link to={URLs.allPostsPath()}>write</Link> and {' '}
          <Link to={URLs.allTalksPath()}>speak</Link> about Engineering,
          Software Architecture, DevOps and on occasion; Security, Design
          and Community Building.
        </p>
      </div>

      <SocialLinks urls={socialLinks}/>
    </header>
  );
};


const SocialLinks = ({ urls }) => (
  <div className={styles.social}>
    {SOCIALS.map(({ name, icon }) => (
      <ExternalLink
        key={name}
        to={urls[name]}
        label={`${_.capitalize(name)} profile link`}
      >
        {icon}
      </ExternalLink>
    ))}
  </div>
);


const RecentContent = ({ posts, talks }) => (
  <main className={styles.recent}>
    <RecentSection
      title="Articles"
      content={posts}
      itemUrl={URLs.postPath}
      allUrl={URLs.allPostsPath}
    />

    <RecentSection
      title="Talks"
      content={talks}
      itemUrl={URLs.talkPath}
      allUrl={URLs.allTalksPath}
    />
  </main>
);


const RecentSection = ({ title, content, itemUrl, allUrl }) => (
  <div className={styles.recentSection}>
    <h3>Recent {title}</h3>

    <ul>
      {content.map(({ node }) => {
        const { title, date, external } = node.frontmatter;
        const slug = node.fields.slug;

        return (
          <li key={slug}>
            <DateTime date={date.published} format="short" />

            <span>
              <Link to={itemUrl(slug)}>
                {title}
              </Link>

              {external && external.url && (
                <ExternalLink to={`${itemUrl(slug)}go`} label="Open External Article">
                  <RiExternalLinkLine className={styles.iconExternal} />
                </ExternalLink>
              )}
            </span>
          </li>
        );
      })}
    </ul>

    <div className={styles.seeMore}>
      <Link to={allUrl()}>See More →</Link>
    </div>
  </div>
);


export default HomePage;
